import React, { useContext, useEffect } from 'react'
import { appsContext } from '../../AppsContext';
import { authContext } from '../../../AuthContext';

function SupervisionLiensHeader() {

  const {loggedInUser} = useContext(authContext);
  const {lastUpdateDate} = useContext(appsContext);

  useEffect(() => {
    console.log(loggedInUser)
  }, [loggedInUser])
  return (

    <div className='grid grid-cols-3 px-6 py-2 items-center text-lg text-gray-100'>
      <div className='font-semibold leading-wide'>{loggedInUser?.compte?.raisonSociale}</div>
      <div className='flex justify-center font-semibold'>Dernière actualisation {lastUpdateDate?.format('YYYY-MM-DD HH:mm:ss')}</div>
      <div className='flex justify-end'><img src="/images/blue-logo-gold.png" className='w-24' alt="Logo BLUE" /></div>
    </div>
  )
}

export default SupervisionLiensHeader