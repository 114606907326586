import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
  split,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { API_URL, API_WS_URL, NODE_ENV, SENTRY_URL } from "./utils/constants";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import secureLocalStorage from "react-secure-storage";

const getUserToken = () => {
  const account = secureLocalStorage.getItem("loggedInUser");
  const token = account?.token;

  return token;
}

const wsLink = new GraphQLWsLink(
  createClient({
    url: API_WS_URL,
    retryAttempts: Infinity, // Pour une reconnexion automatique infinie
    shouldRetry: () => true, // Pour permettre les tentatives de reconnexion
  })
);

const httpLink = createHttpLink({});

const apiLink = setContext((_, { headers }) => {

  const token = getUserToken();

  return {
    headers: {
      ...headers,
      authorization: token ? token : ""
    },
    uri: API_URL,
  };
});

const errorLink = onError(({ graphQLErrors }) => {

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions.code === "UNAUTHENTICATED") {       
        window.location.href = `/logout`;
        break;
      }
    }
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  apiLink.concat(httpLink)
);

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: NODE_ENV,
    integrations: [Sentry.browserTracingIntegration()],

    tracesSampleRate: 0.1,
  });
}

const client = new ApolloClient({
  link: from([errorLink, splitLink]),
  cache: new InMemoryCache(),
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
