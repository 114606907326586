import clsx from 'clsx'
import React from 'react'

function SupervisionLiensBannerItem ({label, value, color}) {

  return (
    <div className='flex flex-col space-y-2'>
      <div className='text-xl font-semibold'>{label}</div>
      <div className={clsx(
        'text-5xl font-bold',
        `text-${color}`
        )}>{value || '-'}</div>
    </div>
  )
}

export default SupervisionLiensBannerItem