import React, { useCallback, useState } from 'react'

export const useApps = () => {
  
  const [lastUpdateDate, setLastUpdateDate] = useState(null);

  const setCurrentLastUpdateDate = useCallback((currentLastUpdateDate) => {
    setLastUpdateDate(currentLastUpdateDate);
  }, []);

  return {
    lastUpdateDate,
    setLastUpdateDate: setCurrentLastUpdateDate,
  };
};
