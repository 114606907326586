import React, { useContext, useEffect, useState } from 'react'
import SupervisionLiensHeader from './SupervisionLiensHeader/SupervisionLiensHeader'
import SupervisionLiensBanner from './SupervisionLiensBanner/SupervisionLiensBanner'
import SupervisionLiensSites from './SupervisionLiensSites/SupervisionLiensSites';

import {appsContext} from '../AppsContext';
import dayjs from 'dayjs';
import { useLazyQuery } from '@apollo/client';
import { GET_SUPERVISION_LIENS_DATA } from './queries';

import Spinner from '../../components/Spinner';

function SupervisionLiens () {

  const {setLastUpdateDate} = useContext(appsContext)

  const [getAppSupervisionLiensData, {data: appSupervisionLiensData}] = useLazyQuery(GET_SUPERVISION_LIENS_DATA, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  })

  const [indicators, setIndicators] = useState([]);
  

  const loadIndicators = () => {

    const {appSupervisionLiens} = appSupervisionLiensData;

    if(!appSupervisionLiens) return;

    const {sitesInactifs, sitesDegrades, sitesOperationnels, nbTickets, nbTravauxProgrammes} = appSupervisionLiens;

    setIndicators([
      {
        label: "Sites inactifs", 
        value: sitesInactifs, 
        color: "red-400"
      },
      {
        label: "Sites dégradés", 
        value: sitesDegrades, 
        color: "amber-300"
      },
      {
        label: "Sites actifs", 
        value: sitesOperationnels, 
        color: "green-300"
      },
      {
        label: "Tickets ouverts", 
        value: nbTickets, 
        color: "blue-400"
      },
      {
        label: "Travaux programmés", 
        value: nbTravauxProgrammes, 
        color: "blue-400"
      }
    ])
  }

  const getSites = () => {

    if(!appSupervisionLiensData) return [];

    const sites = [...appSupervisionLiensData.appSupervisionLiens.sites];

    return sites.sort(site => {
      if(site.prestations.every(prestation => prestation.lien.logins?.every(login => !login.isConnectedBas))) {
        return -1
      }
      return 1
    })
  }

  useEffect(() => {

    if(appSupervisionLiensData) {
      loadIndicators()
      setLastUpdateDate(dayjs())
    }
    
  }, [appSupervisionLiensData])

  useEffect(() => {
    getAppSupervisionLiensData();

    const interval = setInterval(() => {
      getAppSupervisionLiensData();
    }, 300000);

    return () => clearInterval(interval);

  }, [])

  return (
    <div className='h-screen w-screen flex flex-col text-white overflow-hidden'>
      <div>
        <SupervisionLiensHeader />
      </div>
      {
        appSupervisionLiensData ? (
          <>
            <div>
              <SupervisionLiensBanner indicators={indicators} />
            </div>
            <div className='flex-1 overflow-y-auto'>
              <SupervisionLiensSites sites={getSites()}/>
            </div>
          </>
        ) : (
          <div className='flex h-full w-full items-center justify-center'>
            <Spinner size='w-12 h-12' color='white' />
          </div>
        )
      }
      
      
    </div>
  )
}

export default SupervisionLiens