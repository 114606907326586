import React from 'react'
import SupervisionLiensBannerItem from './SupervisionLiensBannerItem'

function SupervisionLiensBanner ({indicators}) {
  return (
    <div className='flex items-center justify-center bg-gray-700 p-4'>
      <div className='grid grid-cols-5 w-4/5'>
        {indicators.map(indicator => (
          <SupervisionLiensBannerItem key={indicator.id} {...indicator} />
        ))}
      </div>
    </div>
  )
}

export default SupervisionLiensBanner