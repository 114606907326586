import React, { useEffect } from 'react'
import secureLocalStorage from 'react-secure-storage';
import { BLUE_PAIRING_URL } from '../../utils/constants';

function Logout () {

  useEffect(() => {
    secureLocalStorage.removeItem("loggedInUser");
    window.location.href = BLUE_PAIRING_URL
  }, [])

  return (
    <></>
  )
}

export default Logout;