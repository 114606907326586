import React, { useContext, useEffect } from 'react'
import SupervisionLiens from './SupervisionLiens/SupervisionLiens'
import { useLazyQuery } from '@apollo/client';
import { IS_LOGGED_IN_QUERY } from './queries';
import { authContext } from '../AuthContext';
import secureLocalStorage from 'react-secure-storage';
import queryString from 'query-string';
import { appsContext } from './AppsContext';
import { useApps } from './UseAppsContext';

const app = {
  code: 'APP_SUPERVISION_LIENS'
}

function Apps () {

  
  const appsCtx = useApps();

  const [isLoggedIn, { data: isLoggedInData }] = useLazyQuery(IS_LOGGED_IN_QUERY, {
    fetchPolicy: "network-only",
  });

  const apiCheck = () => {
    isLoggedIn();
  }

  const { loggedInUser, setLoggedInUser } = useContext(authContext);

  useEffect(() => {

    const params = window.location.search ? queryString.parse(window.location.search) : {};

    const token = params?.token;

    if(token) {
      secureLocalStorage.setItem("loggedInUser", {
        token
      });

      window.location.href = "/"
    }
    else {

      apiCheck();

      const interval = setInterval(() => {
        apiCheck();
      }, 30000);
  
      return () => clearInterval(interval);

    }

    
  }, [])

  useEffect(() => {
    if (isLoggedInData != null && !isLoggedInData.isLoggedIn.success) {
      window.location.href = "/logout"
    }

    /** On met à jour les informations du compte */
    if (loggedInUser && isLoggedInData?.isLoggedIn?.compte) {
      setLoggedInUser(
        {
          ...loggedInUser,
          compte: isLoggedInData.isLoggedIn.compte
        }
      );
    }

  }, [isLoggedInData])

  if(!loggedInUser) {
    return <div>Chargement...</div>
  }

  const renderApps = () => {
    switch(app.code) {
      case 'APP_SUPERVISION_LIENS':
        return (
          <SupervisionLiens />
        )
      default:
        window.location.href = '/logout'
    }
  }

  return (
    <appsContext.Provider value={appsCtx}>
      {renderApps()}
    </appsContext.Provider>
  )
}

export default Apps