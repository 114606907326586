import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import SupervisionLiensPrestationItem from './SupervisionLiensPrestationItem/SupervisionLiensPrestationItem'

const classes = {
  warning: {
    marker: 'bg-amber-300',
    bg: 'bg-gray-700/70'
  },
  danger: {
    marker: 'bg-red-400',
    bg: 'bg-red-900/70'
  },
}

function SupervisionLiensSiteItem ({site}) {

  const [status, setStatus] = useState()

  useEffect(() => {

    /** Si tous les logins ont la valeur isConnectedBas = true alors danger, sinon warning  */
    const isAllLiensDisconnected = site.prestations.every((prestation) =>
      prestation.lien.logins?.every((login) => !login.isConnectedBas)
    );

    setStatus(isAllLiensDisconnected ? 'danger' : 'warning')

  }, [site])

  return (
    <div className='flex rounded-md overflow-hidden w-full text-lg'>
      <div className={
        clsx(
          'h-full w-2',
          classes[status]?.marker
        )
        }></div>
      <div className={
        clsx(
          'h-full px-4 py-4 flex-1 space-y-2',
          classes[status]?.bg
        )
        }>
        <div className='flex flex-col justify-between'>
          <div className='text-xl font-semibold tracking-wide'>#{site.id} {site.nom}</div>
          <div className='font-semibold'>{site.commune} - {site.codePostal}</div>
        </div>
        <div className='flex flex-col gap-2'>
          {
            site?.prestations.map((prestation) => {

              return [...prestation.lien.logins].sort(login => login?.isConnectedBas ? 1 : -1).map(item => (
                <SupervisionLiensPrestationItem login={item} lien={prestation.lien}/>
              ))
            })
          }
        </div>
      </div>
    </div>
  )
}

export default SupervisionLiensSiteItem