import React, { useCallback, useState } from 'react'

import secureLocalStorage from "react-secure-storage";

export const useAuth = () => {
  const _getStoredAccount = () => {
    const account = secureLocalStorage.getItem("loggedInUser");

    return account ? account : null;
  };

  const _setStoredAccount = (loggedInUser) => {

    secureLocalStorage.setItem(
      "loggedInUser",
      loggedInUser || {}
    );

  };

  const [loggedInUser, setLoggedInUser] = useState(_getStoredAccount());

  const setCurrentLoggedInUser = useCallback((currentLoggedInUser) => {
    _setStoredAccount(currentLoggedInUser);
    setLoggedInUser(currentLoggedInUser);
  }, []);

  return {
    loggedInUser,
    setLoggedInUser: setCurrentLoggedInUser,
  };
};
