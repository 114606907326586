import { gql } from "@apollo/client";

export const GET_SUPERVISION_LIENS_DATA = gql`
  query GetSupervisionLiensData {
    appSupervisionLiens {
      nbTickets
      nbTravauxProgrammes
      sitesDegrades
      sitesInactifs
      sitesOperationnels
      sites {
        id
        commune
        nom
        codePostal
        prestations {
          lien {
            travauxProgrammes {
              id
              referenceFournisseur
            }
            logins {
              isConnectedBas
              dateLastDisconnected
              login
              tickets {
                numero
              }
            }
          }
        }
      }
    }
  }
`
