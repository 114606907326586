
import React from "react";
import "./App.css";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Apps from "./Apps/Apps";
import { authContext } from './AuthContext';
import { useAuth } from './UseAuthContext';
import TwPurgeForce from "./TwPurgeForce";
import Logout from "./Apps/Logout/Logout";

function App() {

  const auth = useAuth();

  return (
    <div className='h-screen w-screen bg-dark-900'>
      <authContext.Provider value={auth}>
        <Router>
          <Routes>
            <Route path="/" element={<Apps />}></Route>
            <Route path="/logout" element={<Logout />}></Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </authContext.Provider>
      
    </div>
  )
}

export default App;
