import { CheckCircleIcon } from '@heroicons/react/20/solid'
import React from 'react'
import SupervisionLiensSiteItem from './SupervisionLiensSiteItem'

function SupervisionLiensSites ({sites = []}) {

  if(!sites.length) {
    return (
      <div className='flex flex-col space-y-2 h-full w-full items-center justify-center text-green-400'>
          <div><CheckCircleIcon className='w-16 h-16'/></div>
          <div className='text-xl font-semibold'>Tous les liens sont actifs</div>
      </div>
    )
  }

  return (
    <div className='grid grid-cols-3 p-6 gap-x-6 gap-y-6'>
      {
        sites.map(site => (
          <SupervisionLiensSiteItem key={site.id} site={site} />)
        )
      }
    </div>
  )
}

export default SupervisionLiensSites