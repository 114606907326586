import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

const classes = {
  active: {
    border: 'border-green-300',
    marker: 'bg-green-300',
    bg: 'bg-green-900/50'
  },
  danger: {
    border: 'border-red-400',
    marker: 'bg-red-400',
    bg: 'bg-red-900/70'
  },
  dangerConsidered: {
    border: 'border-blue-200',
    marker: 'bg-blue-200',
    bg: 'bg-red-900/70'
  },
}

function SupervisionLiensPrestationItem ({lien, login}) {

  const [status, setStatus] = useState()

  useEffect(() => {

    let status = 'active'

    if (!login.isConnectedBas) {
      status = 'danger'

      /** Si travail programmé ou ticket */
      if (login.tickets.length > 0 || lien?.travauxProgrammes?.length > 0) {
        status = 'dangerConsidered'
      }
    }

    setStatus(status)
  }, [login])

  return (
    <>
      <div className={
        clsx(
          'flex rounded-sm overflow-hidden w-full flex-1 text-lg border',
          classes[status]?.border
        )}>
        <div className={clsx(
          'h-22 w-2 ',
          classes[status]?.marker
          )}></div>
        <div className={clsx(
          'h-full px-4 py-2 flex-1',
          classes[status]?.bg
          )}>
          <div className='flex flex-col justify-between gap-2'>
            <div className='flex justify-between'>
              <div className='font-semibold tracking-wide'>{login.login}</div>
              {
                !login.isConnectedBas && (
                  <div className=''>{login.dateLastDisconnected}</div>
                )
              }
              
            </div>
            
            {
              login.tickets.length > 0 && (
                <div className='flex gap-1 font-normal'>
                  <div className='font-normal italic'>Tickets :</div>
                  <div className='font-semibold'>
                    {
                      login.tickets.map(ticket => ticket.numero).join(' ; ')
                    }
                  </div>

                </div>
              )
            }
            {
              lien?.travauxProgrammes?.length > 0 && (
                <div className='flex gap-1 font-normal'>
                  <div className='font-normal italic'>TP :</div>
                  <div className='font-semibold'>
                    {
                      lien?.travauxProgrammes?.map(ticket => ticket.referenceFournisseur).join(' ; ')
                    }
                  </div>

                </div>
              )
            }
            
          </div>
        </div>
      </div>
      
    </>
  )

}

export default SupervisionLiensPrestationItem